import { Component } from "@angular/core";

@Component({
    selector: 'app-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent {
    available = true;
    angularDescription = 'Angular has been the primary tool I\'ve used throughout my 5+ year software engineering career. ' +
                         'In this time I\'ve gained a deep understanding of the Angular framework and have been tasked ' +
                         'with designing the UI architecture behind several applications and features.';
    cucumberDescription = 'I have 3+ years of experience writing Cucumber e2e scripts to test UI functionality. ' +
                          'This includes testbeds that use Cucumber with both Protractor and Cypress. '  
    firebaseDescription = 'I use Google Firebase to deploy this very app!' +
                          '<hr>' +
                          '<p>Some of the features I am familiar with and use regularly are:</p>' +
                          '<ul>' +
                               '<li>CLI Deployment</li>' +
                               '<li>Versioning/rollbacks</li>' +
                               '<li>Domain Configuration</li>' +
                          '</ul>';
    unityDescription = 'Creating a successful game with Unity has been a personal goal of mine for some time. ' +
                       'While I haven\'t worked with Unity at a professional level, I have been using the framework for ' +
                       'many years now and am currently working on a narrative strategy game where you play as a hacker.' +
                       '<hr>' +
                       '<p>Some things I have learned using Unity include:</p>' +
                       '<ul>' +
                           '<li>Writing in C#</li>' +
                           '<li>Adapting to a new, very different, development environment (web vs. game development)</li>' +
                           '<li>Network  communication between multiple clients in realtime</li>' +
                       '</ul>';
    jasmineDescription = 'I use Jasmine along with Karma to write unit tests for the applications I work on. These tests must meet minimum ' +
                       'code coverage requirements of 85% for lines and statements.';
    cypressDescription = 'Cypress (in conjunction with Cucumber) is the framework I use for end to end automated testing and integration testing.' +
                        'This includes navigating through entire UI flows as well as testing API calls.'
}
