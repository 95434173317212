import { Component } from "@angular/core";
import { GameUpdateComponent, GameUpdateItem } from "../app-common/game-update/game-update.component";

@Component({
    selector: 'app-zero-day-updates',
    templateUrl: './zero-day-updates.component.html',
    styleUrls: ['./zero-day-updates.component.scss']
})
export class ZeroDayUpdatesComponent {
    gameUpdates: GameUpdateItem[] = [
        {
            header: 'Immersive Room',
            imagePath: 'assets/gifs/zeroday/Immersion.gif',
            bodyText: 'A big part of this game is the concept of immersing the player in the life of the main character. We want ' +
                'you to feel as though you are the hacker sitting in front of your computer screen trying to tackle a corrupt government. ' +
                'To add to this feeling we added things like a working fan to turn on and off, food and drink that can be eaten, ' +
                'and movement on the characters hand when moving the mouse on screen and clicking.'
        },
        {
            header: 'Coin Crush App!',
            imagePath: "assets/gifs/zeroday/CoinCrush.gif",
            bodyText: "The first in game app is up and running! \"Coin Crush\" will be a manual way to earn in game currency " +
                "early on before you unlock other passive forms of currency generation."
        },
        {
            header: 'Take a Look Outside!',
            imagePath: 'assets/gifs/zeroday/OutsideView.gif',
            bodyText: 'We\'ve added the ability to look outside at the street below. This will eventually be worked into the ' +
                'story in various ways and will also provide opportunities to "hack" individuals and vehicles for currency or influence.'
        },
        {
            header: 'Street Light and Crosswalk',
            imagePath: 'assets/gifs/zeroday/Crosswalk.gif',
            bodyText: "To add some more realism to the outside environment we added a working streetlight! The next " +
                "step will be to add some cars which will react to the light changes and watch out for pedestrians crossing the street."
        }
    ];
}