<div class="main-container {{lookAndFeelService.getLookAndFeel()}}">
    <div class="px-3 pb-3">
        <div *ngFor="let displayedColumn of displayedColumns" 
             class="filter-container p-2 col-12 col-md-3">
            <label class="field-label">{{displayNameMap[displayedColumn]}}</label>
            <input [formControl]="filtersFormGroup.controls[displayedColumn]" class="filter-input" type="text">
        </div>
    </div>
    <div class="col-12 col-md-4 px-4 pb-4">
        <button (click)="applyFilters()" class="btn-primary w-100 p-1">Filter</button>
    </div>
</div>
<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" class="{{lookAndFeelService.getLookAndFeel()}}">
        <ng-container *ngFor="let displayedColumn of displayedColumns" matColumnDef="{{displayedColumn}}">
            <th mat-header-cell *matHeaderCellDef>{{displayNameMap[displayedColumn]}}</th>
            <td [style.width.%]="widthMap[displayedColumn]" mat-cell *matCellDef="let element">{{element[displayedColumn]}}</td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                   class="{{lookAndFeelService.getLookAndFeel()}}"></mat-paginator>
  </div>