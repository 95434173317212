import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';

export enum TickerDateRange {
    Hourly,
    Daily,
    Weekly,
    Monthly,
    TriMonthly,
    Yearly,
    PentiYearly
}

@Injectable()
export class CryptoTickerService {
    static ONE_HOUR = 3600;
    static ONE_DAY = 86400;
    static ONE_WEEK = 604800;
    static ONE_MONTH = 2629743;
    static THREE_MONTHS = 7889229;
    static ONE_YEAR = 31556926;
    static FIVE_YEARS = 157784630;
    timer = timer(5 * 1000);
    subManager = new Subscription();
    url = 'https://api.coingecko.com/api/v3/coins/{coinName}/market_chart/range?vs_currency={currency}&from={from}&to={to}';

    constructor(private http: HttpClient) {
        
    }
    getHistoricalData(coin: string, range: TickerDateRange): Observable<any> {
        let newUrl = this.url.replace('{coinName}', coin).replace('{currency}', 'usd');
        const now = Math.round(new Date().getTime() / 1000);
        let from = now;
        switch (range) {
            case TickerDateRange.Hourly:
                from -= CryptoTickerService.ONE_HOUR;
                break;
            case TickerDateRange.Daily:
                from -= CryptoTickerService.ONE_DAY;
                break;
            case TickerDateRange.Weekly:
                from -= CryptoTickerService.ONE_WEEK;
                break;
            case TickerDateRange.Monthly:
                from -= CryptoTickerService.ONE_MONTH;
                break;
            case TickerDateRange.TriMonthly:
                from -= CryptoTickerService.THREE_MONTHS;
                break;
            case TickerDateRange.Yearly:
                from -= CryptoTickerService.ONE_YEAR;
                break;
            case TickerDateRange.PentiYearly:
                from -= CryptoTickerService.FIVE_YEARS;
                break;                    
        }
        newUrl = newUrl.replace('{from}', from.toString());
        newUrl = newUrl.replace('{to}', now.toString());
        return this.http.get(newUrl);
    }
}