<div class="row no-gutter">
    <div class="col-12 col-md-6 col-lg-5 pl-md-0">
        <div class="card status-card">
            <div class="card-header">
                <h1>
                    My Experience
                </h1>
            </div>
            <div class="card-body experience-container">
                <h2>Education:</h2>
                <div class="education-container d-flex pb-4">
                    <div class="pr-3">
                        <img src="assets/logos/UconnLogo.png" height="110">
                    </div>
                    <div>
                        <h3>UConn</h3>
                        <p>Bachelor of Science in Engineering</p>
                        <p>Computer Science and Engineering Major</p>
                    </div>
                </div>
                <h2>Employment:</h2>
                <div class="employment-container d-flex pb-4">
                    <div class="pr-3">
                        <img src="assets/logos/HartfordLogo.png" height="110">
                    </div>
                    <div>
                        <h3>The Hartford</h3>
                        <p>2017 - Present</p>
                        <p>Current Position: Senior Software Engineer</p>
                    </div>
                </div>
                <h2>Frameworks:</h2>
                <div class="logo-popups pb-4">
                    <div class="logo-popup-container">
                        <app-logo-popup logoImageSrc="assets/logos/AngularLogo.png" imgHeight="70" imgWidth="70"
                            modalHeader="Angular" [modalBody]="angularDescription"></app-logo-popup>
                    </div>
                    <div class="logo-popup-container">
                        <app-logo-popup logoImageSrc="assets/logos/CypressLogo.png" imgHeight="70" imgWidth="70"
                            modalHeader="Cypress" [modalBody]="cypressDescription"></app-logo-popup>
                    </div>
                    <div class="logo-popup-container">
                        <app-logo-popup logoImageSrc="assets/logos/CucumberLogo.png" imgHeight="70" imgWidth="70"
                            modalHeader="Cucumber" [modalBody]="cucumberDescription"></app-logo-popup>
                    </div>
                    <div class="logo-popup-container">
                        <app-logo-popup logoImageSrc="assets/logos/JasmineLogo.png" imgHeight="70" imgWidth="70" modalHeader="Jasmine"
                            [modalBody]="jasmineDescription"></app-logo-popup>
                    </div>
                    <div class="logo-popup-container">
                        <app-logo-popup logoImageSrc="assets/logos/FirebaseLogo.png" imgHeight="70" imgWidth="70"
                            modalHeader="Firebase" [modalBody]="firebaseDescription"></app-logo-popup>
                    </div>
                    <div class="logo-popup-container">
                        <app-logo-popup logoImageSrc="assets/logos/UnityLogo.png" imgHeight="70" imgWidth="70" modalHeader="Unity"
                            [modalBody]="unityDescription"></app-logo-popup>
                    </div>
                </div>
                <h2>Programming Languages:</h2>
                <ul>
                    <li>Typescript/Javascript</li>
                    <li>HTML5</li>
                    <li>CSS/SCSS</li>
                    <li>Gherkin</li>
                    <li>C#</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 col-lg-7 pr-md-0 pt-3 pt-md-0">
        <div class="col-12 px-0">
            <div class="card">
                <div class="card-header m-0">
                    <h1 class="header-text">About Me</h1>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <p>
                                Hello! My Name is Erik Green and I am a Software Engineer from Connecticut. I've had the software engineering
                                itch since I was very young: in my spare time as a kid I would mod video games and create .bat files
                                to simplify tasks I would perform on my PC. That passion for developing has never left me!
                                I am currently a software engineer for The Hartford insurance and do everything I can, both at work
                                and at home, to further my knowledge of all sorts of software development. I am married to my lovely
                                wife Beatriz and we have a son named Tyler and a dog named Chopsticks.
                            </p>
                            <h2>My Family</h2>
                            <p>Here's me and my beautiful family!</p>
                            <img src="assets/pictures/Family.jpg" class="d-block m-auto family-img">
                            <h2 class="pt-3">This Site</h2>
                            <p>This site is something I started without much of a plan in mind: I figured that I spent so much time experimenting with 
                                different projects and components why not start saving them somewhere to look back on? Some day I hope it will serve 
                                as a sort of record of my progression as a developer so I'm able to see how I've grown through the years.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Current Freelance Status Section, might be too forthright -->
    <!-- <div class="col-12 col-md-4 pl-md-0 pt-3 pt-md-0 order-2 order-md-1">
        <div class="card status-card">
            <div class="card-header">
                <h1>
                    Current Freelance Status
                </h1>
            </div>
            <div class="card-body">
                <div class="status-container">
                    <ng-container *ngIf="available; else Unavailable">
                        <div class="available-container">
                            <i aria-hidden="true" class="fas fa-check-circle pb-2"></i>
                            <h2>Available!</h2>
                            <p>Currently availble to work 20 hrs per week</p>
                        </div>    
                    </ng-container>
                    <ng-template #Unavailable>
                        <div class="unavailable-container">
                            <i aria-hidden="true" class="fas fa-times-circle"></i>
                            <h2>Unavailable</h2>
                            <p>Not currently available</p>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="row no-gutter pt-3">
    <div class="col-12 p-md-0">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-4">
                        <img src="../../assets/pictures/fishing.jpg" width="100%">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->