import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { AppCommonModule } from "src/app/app-common/app-common.module";
import { DynamicFormComponent } from "./dynamic-form.component";
import { AddressFormComponent } from "./address-form/address-form.component"

@NgModule({
    imports: [
        CommonModule,
        AppCommonModule,
        ReactiveFormsModule
    ],
    declarations: [
        DynamicFormComponent,
        AddressFormComponent
    ],
    exports: [
        DynamicFormComponent
    ]
})
export class DynamicFormModule {}
