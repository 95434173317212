import { NgModule } from "@angular/core";
import { KillerComponent } from "./killer/killer.component";
import { KillerNewComponent } from "./killer-new/killer-new.component";

@NgModule({
    declarations: [
        KillerComponent,
        KillerNewComponent
    ],
    exports: [
        KillerComponent,
        KillerNewComponent
    ]
})
export class EmbeddedComponentsModule {}