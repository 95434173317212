import { NgModule } from "@angular/core";
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CommonModule } from '@angular/common';
import { LookAndFeelComponent } from "../components/look-and-feel/look-and-feel.component";
import { RouterModule } from "@angular/router";
import { NavbarAccordionComponent } from "./navbar-accordion/navbar-accordion.component";
import { DropdownPanelComponent } from "./dropdown-panel/dropdown-panel.component";

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        LookAndFeelComponent,
        NavbarAccordionComponent,
        DropdownPanelComponent
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        LookAndFeelComponent,
        NavbarAccordionComponent,
        DropdownPanelComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ]
})
export class LayoutModule {
    
}