import { Component, OnInit } from "@angular/core";
import { LookAndFeelOption, LookAndFeelService } from "src/app/app-common/services/look-and-feel.service";
import { HeaderNavigationLinks } from "./header-navigation-links"

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    constructor(public lookAndFeelService: LookAndFeelService) {
    }
    headerLinks = HeaderNavigationLinks;
}