import { Component } from "@angular/core";
import { CdkDragDrop, CdkDrag, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";

interface TowerData {
    value: number;
    imgSrc: string;
}

@Component({
    selector: 'app-tower-of-hanoi',
    templateUrl: './tower-of-hanoi.component.html',
    styleUrls: ['./tower-of-hanoi.component.scss']
})
export class TowerOfHanoiComponent {
    gameWon = false;
    tower1Data: TowerData[] = [
        {
            value: 1,
            imgSrc: 'Bar1.png'
        },
        {
            value: 2,
            imgSrc: 'Bar2.png'
        },
        {
            value: 3,
            imgSrc: 'Bar3.png'
        },
        {
            value: 4,
            imgSrc: 'Bar4.png'
        },
        {
            value: 5,
            imgSrc: 'Bar5.png'
        }
    ];
    tower2Data: TowerData[] = [];
    tower3Data: TowerData[] = [];

    drop(event: CdkDragDrop<TowerData[]>) {
        if (event.previousContainer !== event.container) {
            if (event.previousIndex === 0 && (!event.container.data[0] || event.item.data.value < event.container.data[0].value)) {
                    transferArrayItem(event.previousContainer.data,
                            event.container.data,
                            event.previousIndex,
                            0);
                    this.gameWon = this.checkForWin();        
            }
        }
    }
    checkForWin(): boolean {
        if (this.tower3Data.length !== 5) {
            return false;
        }
        this.tower3Data.forEach((item, index) => {
            if (item.value !== index + 1) {
                return false;
            }
        });
        return true;
    }
}