import { Component, Input } from '@angular/core';

export interface GameUpdateItem {
    bodyText: string;
    imagePath: string;
    header: string;
    width?: string;
    rightImage?: boolean;
}

@Component({
    selector: 'app-game-update',
    templateUrl: './game-update.component.html',
    styleUrls: ['./game-update.component.scss']
})
export class GameUpdateComponent {
    @Input() header: string;
    @Input() bodyText: string;
    @Input() imagePath: string;
    @Input() width: string;
    @Input() rightImage: boolean;
}