import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LookAndFeelService } from "src/app/app-common/services/look-and-feel.service";

interface CustomerDatum {
    firstName: string;
    lastName: string,
    age: number,
    occupation: string
}

interface Filter {
    field: string;
    value: string | number;
}

@Component({
    selector: 'app-table-with-filters',
    templateUrl: './table-with-filters.component.html',
    styleUrls: ['./table-with-filters.component.scss']
})
export class TableWithFiltersComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @Input() displayedColumns: string[] = ['firstName', 'lastName', 'age', 'occupation'];
    @Input() displayNameMap = { firstName: 'First Name', lastName: 'Last Name', age: 'Age', occupation: 'Occupation' };
    @Input() widthMap = { firstName: '25', lastName: '25', age: '25', occupation: '25' };
    @Input() originalData: CustomerDatum[] = [
        {firstName: 'Joe', lastName: 'Schmoe', age: 42, occupation: 'Carpenter'},
        {firstName: 'Lisa', lastName: 'Finnegan', age: 38, occupation: 'Psychologist'},
        {firstName: 'Sarah', lastName: 'Pategas', age: 26, occupation: 'Nurse'},
        {firstName: 'Carmine', lastName: 'Dominicus', age: 28, occupation: 'Film Editor'},
        {firstName: 'Lindsay', lastName: 'Leigh', age: 27, occupation: 'Software Engineer'},
        {firstName: 'Barabara', lastName: 'Johnson', age: 53, occupation: 'Sales Representative'},
        {firstName: 'Steven', lastName: 'Smith', age: 39, occupation: 'Plumber'},
        {firstName: 'Larry', lastName: 'Brown', age: 45, occupation: 'Carpenter'},
        {firstName: 'John', lastName: 'Carreiro', age: 28, occupation: 'Mechanic'},
        {firstName: 'Curtis', lastName: 'Rose', age: 26, occupation: 'Nurse'},
        {firstName: 'Jerry', lastName: 'Jackson', age: 53, occupation: 'Carpenter'},
        {firstName: 'Bill', lastName: 'Butler', age: 42, occupation: 'Software Engineer'},
        {firstName: 'Erik', lastName: 'Green', age: 28, occupation: 'Software Engineer'},
        {firstName: 'Linda', lastName: 'Stevens', age: 27, occupation: 'Plumber'},
        {firstName: 'Lisa', lastName: 'Flynn', age: 45, occupation: 'Psychologist'},
        {firstName: 'Tom', lastName: 'Morrison', age: 28, occupation: 'Sales Representative'},
        {firstName: 'Gregg', lastName: 'Hale', age: 26, occupation: 'Mechanic'},
    ];
    data: any;
    dataSource: MatTableDataSource<any>;
    @Input() columnWidth = '25';

    filtersFormGroup: FormGroup;
    currentFilters: Filter[] = [];

    constructor(public lookAndFeelService: LookAndFeelService) {}

    ngOnInit() {
        this.data = JSON.parse(JSON.stringify(this.originalData));
        this.dataSource = new MatTableDataSource<any>(this.data);
        this.filtersFormGroup = new FormGroup({});
        this.displayedColumns.forEach((column) => {
            this.filtersFormGroup.addControl(column, new FormControl())
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    applyFilters() {
        Object.keys(this.filtersFormGroup.controls).forEach((key) => {
            const currentFilter = this.currentFilters.find(filter => filter.field === key);
            const currentFilterIndex = this.currentFilters.findIndex(filter => filter.field === key);
            if (this.filtersFormGroup.value[key]) {
                if (currentFilter) {
                    this.currentFilters.splice(currentFilterIndex, 1);
                }
                this.currentFilters.push({field: key, value: this.filtersFormGroup.value[key]});
            } else {
                if (currentFilter) {
                    this.currentFilters.splice(currentFilterIndex, 1);
                }
            }
        });
        this.data = JSON.parse(JSON.stringify(this.originalData));
        this.currentFilters.forEach((filter) => {
            for (let i = this.data.length - 1; i >= 0; i--) {
                const current = this.data[i][filter.field].toString();
                const applying = filter.value.toString();
                if (current.toLocaleLowerCase() !== applying.toLocaleLowerCase()) {
                    this.data.splice(i, 1);
                }
            }
        });
        this.dataSource = new MatTableDataSource<any>(this.data);
        this.dataSource.paginator = this.paginator;
    }
}
