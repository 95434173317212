import { NgModule } from '@angular/core';
import { LogoPopupComponent, LogoPopupContentComponent } from './logo-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule
    ],
    declarations: [
        LogoPopupComponent,
        LogoPopupContentComponent
    ],
    exports: [
        LogoPopupComponent
    ]
})
export class LogoPopupModule {

}