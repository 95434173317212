import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from './main-page/main-page.component';
import { GraphsAndChartsComponent } from './graphs-and-charts/graphs-and-charts.component';
import { ChatAppComponent } from './components/chat-app/chat-app.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { KillerComponent } from './embedded-components/killer/killer.component';
import { TableWithFiltersPageComponent } from './components/table-with-filters/table-with-filters-page.component';
import { JustForFunComponent } from './just-for-fun/just-for-fun.component';
import { CryptoTickerComponent } from './components/crypto-ticker/crypto-ticker.component';
import { KillerNewComponent } from './embedded-components/killer-new/killer-new.component';
import { ZeroDayUpdatesComponent } from './zero-day-updates/zero-day-updates.component';


const routes: Routes = [
  { path: 'graphs', component: GraphsAndChartsComponent },
  { path: 'chat', component: ChatAppComponent },
  { path: 'dynamicform', component: DynamicFormComponent },
  { path: 'killer', component: KillerComponent },
  { path: 'killer-new', component: KillerNewComponent },
  { path: 'table', component: TableWithFiltersPageComponent },
  { path: 'just-for-fun', component: JustForFunComponent },
  { path: 'crypto-ticker', component: CryptoTickerComponent },
  { path: 'zero-day', component: ZeroDayUpdatesComponent },
  { path: '**', component: MainPageComponent, data: {'title': 'Homepage'} }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
