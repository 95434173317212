import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AppCommonModule } from "../app-common/app-common.module";
import { ZeroDayUpdatesComponent } from "./zero-day-updates.component";

@NgModule({
    imports: [ AppCommonModule, CommonModule ],
    declarations: [ ZeroDayUpdatesComponent ],
    exports: [ ZeroDayUpdatesComponent ]
})
export class ZeroDayUpdatesModule {}