import { NgModule } from "@angular/core";
import { MainPageComponent } from './main-page.component';
import { LeftRailComponent } from './left-rail/left-rail.component';
import { CommonModule } from '@angular/common';
import { LogoPopupModule } from "../components/logo-popup/logo-popup.module";

@NgModule({
    declarations: [
        MainPageComponent,
        LeftRailComponent
    ],
    exports: [
        LeftRailComponent
    ],
    imports: [
        CommonModule,
        LogoPopupModule
    ]
})
export class MainPageModule {}