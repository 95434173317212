<!-- <div class="card">
    <div class="card-header">
        <h1>Highcharts Examples</h1>
    </div>
    <ng-container *ngIf="!candlestickLoading && chartData; else loading">
        <div>
            <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="chartData"
                [constructorType]="'stockChart'"
                style="width: 100%; height: 400px; display: block;">
            </highcharts-chart>
        </div>
    </ng-container>
    <ng-template #loading>
        <div>
            <app-loading-spinner style="width: 100%; height: 400px; display: block;"></app-loading-spinner>
        </div>
    </ng-template>
</div> -->
<div class="card mt-3">
    <div class="card-header">
        <h1>Chart.js Example</h1>
    </div>
    <div class="row p-5">
        <div class="col-12 col-md-8 order-2 order-md-1" style="height: 400px;">
            <canvas baseChart
                [data]="doughnutChartData"
                [labels]="doughnutChartLabels"
                [options]="doughnutChartOptions"
                chartType="doughnut">
            </canvas>
        </div>
        <div class="col-12 col-md-4 order-1 order-md-2 pb-5">
            <form [formGroup]="doughnutChartForm">
                <label for="downloadSalesInput" class="field-label mt-3">Download Sales</label>
                <input id="downloadSalesInput" type="text" formControlName="downloadSales">
                <label for="inStoreSalesInput" class="field-label mt-3">In Store Sales</label>
                <input id="inStoreSalesInput" type="text" formControlName="inStoreSales">
                <label for="mailOrderSalesInput" class="field-label mt-3">Mail Order Sales</label>
                <input id="mailOrderSalesInput" type="text" formControlName="mailOrderSales">
            </form>
        </div>
    </div>
</div>