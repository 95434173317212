<div class="card">
    <div class="pl-4">
        <div *ngIf="chartData$ | async as chartData" class="spot-container pr-4">
            <h1 class="mb-2">Bitcoin</h1>
            <span>{{(chartData.prices[chartData.prices.length - 1][chartData.prices[chartData.prices.length - 1].length - 1]) | currency}}</span>
        </div>
        <div class="button-container">
            <h2>Pick Range</h2>
            <select (change)="getCryptoData($event.target.value)">
                <option [value]="tickerWeekly">1 Week</option>
                <option [value]="tickerMonthly">1 Month</option>
                <option [value]="tickerTriMonthly">3 Months</option>
                <option [value]="tickerYearly">1 Year</option>
                <option [value]="tickerPentiYearly">5 Years</option>
            </select>
        </div>
    </div>
    <div *ngIf="datasets && !tooManyRequests">
        <canvas class="chart-canvas"
                baseChart
                [datasets]="datasets"
                [labels]="historicalCryptoData.labels"
                [options]="options"
                chartType="line">
        </canvas>
    </div>
    <h2 class="error-message" *ngIf="tooManyRequests">Too Many Requests Made!</h2>
</div>
