<div *ngIf="gameWon" class="row win-text mb-3">
    <h1>You Win!!!</h1>
</div>
<div class="row">
    <div class="col-4">
        <div class="tower-container">
            <div class="placement-bar">
                <img draggable="false" src="assets/tower-of-hanoi/PlacementBar.png">
            </div>
            <div cdkDropList class="list" id="tower-1" [cdkDropListConnectedTo]="['tower-2', 'tower-3']"
                [cdkDropListData]="tower1Data" (cdkDropListDropped)="drop($event)">
                <div class="list-container">
                        <img cdkDrag class="list-item" [cdkDragData]="item" *ngFor="let item of tower1Data; let i = index"
                            [style]="'bottom: ' + ((.5 + tower1Data.length - i) * 1.1) + 'em'"
                            [cdkDragDisabled]="i !== 0" [draggable]="i === 0" src="{{'assets/tower-of-hanoi/' + item.imgSrc}}">
                </div>
            </div>
        </div>
    </div>
    <div class="col-4">
        <div class="tower-container">
            <div class="placement-bar">
                <img draggable="false" src="assets/tower-of-hanoi/PlacementBar.png">
            </div>
            <div cdkDropList class="list" id="tower-2" [cdkDropListConnectedTo]="['tower-1', 'tower-3']"
                [cdkDropListData]="tower2Data" (cdkDropListDropped)="drop($event)">
                <div class="list-container">
                    <img cdkDrag class="list-item" [cdkDragData]="item" *ngFor="let item of tower2Data; let i = index"
                        [style]="'bottom: ' + ((.5 + tower2Data.length - i) * 1.1) + 'em'"
                        [cdkDragDisabled]="i !== 0" [draggable]="i === 0" src="{{'assets/tower-of-hanoi/' + item.imgSrc}}">
                </div>
            </div>
        </div>
    </div>
    <div class="col-4">
        <div class="tower-container">
            <div class="placement-bar">
                <img draggable="false" src="assets/tower-of-hanoi/PlacementBar.png">
            </div>
            <div cdkDropList class="list" id="tower-3" [cdkDropListConnectedTo]="['tower-1', 'tower-2']"
                [cdkDropListData]="tower3Data" (cdkDropListDropped)="drop($event)">
                <div class="list-container">
                    <img cdkDrag class="list-item" [cdkDragData]="item" *ngFor="let item of tower3Data; let i = index"                            [style]="'bottom: ' + ((.5 + tower1Data.length - i) * 1.1) + 'em'"
                        [style]="'bottom: ' + ((.5 + tower3Data.length - i) * 1.1) + 'em'"
                        [cdkDragDisabled]="i !== 0" [draggable]="i === 0" src="{{'assets/tower-of-hanoi/' + item.imgSrc}}">
                </div>
            </div>
        </div>
    </div>
</div>