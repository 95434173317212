import { Injectable } from "@angular/core";


export enum LookAndFeelOption {
    Standard,
    Dark
}

@Injectable({
    providedIn: 'root'
})
export class LookAndFeelService {
    private _currentLookAndFeel: LookAndFeelOption = LookAndFeelOption.Standard;
    
    public setLookAndFeel(lookAndFeelOption: LookAndFeelOption): void {
        this._currentLookAndFeel = lookAndFeelOption;
        document.getElementById('index-body').className = this.getLookAndFeel();
    }
    public getLookAndFeel(): string {
        switch(this._currentLookAndFeel) {
            case LookAndFeelOption.Standard:
                return 'laf-standard';
            case LookAndFeelOption.Dark:
                return 'laf-dark';
            default:
                break;    
        }
    }
}