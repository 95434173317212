import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Observable, of, Subject } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CryptoTickerService, TickerDateRange } from './crypto-ticker.service';

interface HistoricalCrytpoData {
    prices: any;
}

@Component({
    selector: 'app-crypto-ticker',
    templateUrl: './crypto-ticker.component.html',
    styleUrls: ['./crypto-ticker.component.scss']
})
export class CryptoTickerComponent implements OnInit, AfterViewInit {
    tickerDaily = TickerDateRange.Daily as number;
    tickerWeekly = TickerDateRange.Weekly as number;
    tickerMonthly = TickerDateRange.Monthly as number;
    tickerTriMonthly = TickerDateRange.TriMonthly as number;
    tickerYearly = TickerDateRange.Yearly as number;
    tickerPentiYearly = TickerDateRange.PentiYearly as number;
    currentCoin = 'bitcoin';
    green = "rgba(30, 252, 75)";
    tooManyRequests: boolean;
    private dateRange$ = new Subject<TickerDateRange>();
    chartData$: Observable<any>;
    historicalCryptoData: {
        prices: any[],
        labels: any[]
    };
    options: ChartOptions = {
        tooltips: {
            mode: 'index',
            intersect: false
         },
         responsive: true,
         hover: {
            mode: 'index',
            intersect: false
         },
         legend: {
             display: false
         },
         scales: {
            xAxes: [{
                display: false,
                gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                }
            }],
            yAxes: [{
                display: false,
                gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                }   
            }]
        }
    };
    datasets: ChartDataSets[];

    constructor(private cryptoTickerService: CryptoTickerService) {
    }

    ngOnInit() {
        this.chartData$ = this.dateRange$.pipe(
            tap(() =>
                // clear data
                this.historicalCryptoData = {
                    prices: [],
                    labels: []
            }),
            switchMap((range) => {
                this.tooManyRequests = false;
                // combine range and response for 
                return this.cryptoTickerService.getHistoricalData(this.currentCoin, range).pipe(
                    tap((data) => {
                        if (!data.prices) return;
                        data.prices.forEach((element, index) => {
                            if (range !== TickerDateRange.Monthly || (new Date(element[0]).getHours() === 20 || index === data.prices.length - 1)) {
                                this.historicalCryptoData.prices.push(element[1]);
                                this.historicalCryptoData.labels.push(element[0]);
                            }
                        });
                        this.datasets = [{
                            data: this.historicalCryptoData.prices,
                            borderColor: this.green,
                            fill: false,
                            lineTension: 0,
                            pointRadius: 0,
                            hoverRadius: 0
                        }];
                    }),
                    catchError(() => {
                        this.tooManyRequests = true;
                        this.historicalCryptoData = {
                            prices: [],
                            labels: []
                        }
                        return of({});
                    })
                );
            })
        )
    }

    ngAfterViewInit(): void {
        this.getCryptoData(TickerDateRange.Weekly.toString());
    }

    getCryptoData(range: string) {
        this.dateRange$.next(parseInt(range) as TickerDateRange);
    }
}