<footer class="{{lookAndFeelService.getLookAndFeel()}}" role="contentinfo">
    <div class="row no-gutter pt-2">
        <div class="col-6">
            <div class="row no-gutter">
                <strong class="col-12 get-in-touch">
                    Get in Touch:
                </strong>
                <!-- <div class="col-12">
                    <a target="_blank" href="https://www.upwork.com/o/profiles/users/~0185e7961bdb2ff93c/">Upwork</a>
                </div> -->
                <div class="col-12">
                    <a href="mailto: erik.d.green@gmail.com">Send Email</a>
                </div>
                <div class="col-12">
                    <a target="_blank" href="https://www.linkedin.com/in/erik-green-742835132/">LinkedIn</a>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div [ngSwitch]="lookAndFeelService.getLookAndFeel()">
                <a *ngSwitchCase="'laf-standard'" class="float-right" href='https://www.freepik.com/vectors/background'>Background vector created by freepik - www.freepik.com</a>
                <a *ngSwitchCase="'laf-dark'" class="float-right" href='https://www.freepik.com/free-photos-vectors/background'>Background vector created by freepik - www.freepik.com</a>
            </div>
        </div>
    </div>
</footer>