import { NgModule } from "@angular/core";
import { FriendsListComponent } from './friends-list/friends-list.component';
import { ChatAreaComponent } from './chat-area/chat-area.component';
import { ChatAppComponent } from './chat-app.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        ChatAppComponent,
        FriendsListComponent,
        ChatAreaComponent
    ],
    exports: [
        ChatAppComponent,
        FriendsListComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class ChatAppModule {

}