import { Injectable } from "@angular/core";

interface StateOption {
    stateFull: string;
    stateShort: string;
}

@Injectable({
    providedIn: 'root'
})
export class StateServcie {
    getStateOptions(): StateOption[] {
        return [
            {
                stateFull: 'Alabama',
                stateShort: 'AL'
            },
            {
                stateFull: 'Alaska',
                stateShort: 'AK'
            },
            {
                stateFull: 'Arizona',
                stateShort: 'AZ'
            },
            {
                stateFull: 'Arkansas',
                stateShort: 'AR'
            },
            {
                stateFull: 'California',
                stateShort: 'CA'
            },
            {
                stateFull: 'Colorado',
                stateShort: 'CO'
            },
            {
                stateFull: 'Connecticut',
                stateShort: 'CT'
            },
            {
                stateFull: 'Delaware',
                stateShort: 'DE'
            },
            {
                stateFull: 'Florida',
                stateShort: 'FL'
            },
            {
                stateFull: 'Georgia',
                stateShort: 'GA'
            },
            {
                stateFull: 'Hawaii',
                stateShort: 'HI'
            },
            {
                stateFull: 'Idaho',
                stateShort: 'ID'
            },
            {
                stateFull: 'Illinois',
                stateShort: 'IL'
            },
            {
                stateFull: 'Indiana',
                stateShort: 'IN'
            },
            {
                stateFull: 'Iowa',
                stateShort: 'IA'
            },
            {
                stateFull: 'Kansas',
                stateShort: 'KS'
            },
            {
                stateFull: 'Kentucky',
                stateShort: 'KY'
            },
            {
                stateFull: 'Louisiana',
                stateShort: 'LA'
            },
            {
                stateFull: 'Maine',
                stateShort: 'ME'
            },
            {
                stateFull: 'Maryland',
                stateShort: 'MD'
            },
            {
                stateFull: 'Massachusetts',
                stateShort: 'MA'
            },
            {
                stateFull: 'Michigan',
                stateShort: 'MI'
            },
            {
                stateFull: 'Minnesota',
                stateShort: 'MN'
            },
            {
                stateFull: 'Mississippi',
                stateShort: 'MS'
            },
            {
                stateFull: 'Missouri',
                stateShort: 'MO'
            },
            {
                stateFull: 'Montana',
                stateShort: 'MT'
            },
            {
                stateFull: 'Nebraska',
                stateShort: 'NE'
            },
            {
                stateFull: 'Nevada',
                stateShort: 'NV'
            },
            {
                stateFull: 'New Hampshire',
                stateShort: 'NH'
            },
            {
                stateFull: 'New Jersey',
                stateShort: 'NJ'
            },
            {
                stateFull: 'New Mexico',
                stateShort: 'NM'
            },
            {
                stateFull: 'New York',
                stateShort: 'NY'
            },
            {
                stateFull: 'North Carolina',
                stateShort: 'NC'
            },
            {
                stateFull: 'North Dakota',
                stateShort: 'ND'
            },
            {
                stateFull: 'Ohio',
                stateShort: 'OH'
            },
            {
                stateFull: 'Oklahoma',
                stateShort: 'OK'
            },
            {
                stateFull: 'Oregon',
                stateShort: 'OR'
            },
            {
                stateFull: 'Pennsylvania',
                stateShort: 'PA'
            },
            {
                stateFull: 'Rhode Island',
                stateShort: 'RI'
            },
            {
                stateFull: 'South Carolina',
                stateShort: 'SC'
            },
            {
                stateFull: 'South Dakota',
                stateShort: 'SD'
            },
            {
                stateFull: 'Tennessee',
                stateShort: 'TN'
            },
            {
                stateFull: 'Texas',
                stateShort: 'TX'
            },
            {
                stateFull: 'Utah',
                stateShort: 'UT'
            },
            {
                stateFull: 'Vermont',
                stateShort: 'VT'
            },
            {
                stateFull: 'Virginia',
                stateShort: 'VA'
            },
            {
                stateFull: 'Washington',
                stateShort: 'WA'
            },
            {
                stateFull: 'West Virginia',
                stateShort: 'WV'
            },
            {
                stateFull: 'Wisconsin',
                stateShort: 'WI'
            },
            {
                stateFull: 'Wyoming',
                stateShort: 'WY'
            }
        ]
    }
}