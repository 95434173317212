import { Component, Output, EventEmitter, Input } from "@angular/core";

@Component({
    selector: 'app-friends-list',
    templateUrl: './friends-list.component.html',
    styleUrls: ['./friends-list.component.scss']
})
export class FriendsListComponent {
    @Output() friendClicked = new EventEmitter<string>();
    @Input() selectedFriend: string;
    friends = ['ChatTestBot', 'John4444', 'GreenMachine2o3', 'VeryLongUsername55442244edwqdwdwdwd'];

    emitFriend(friend: string) {
        this.friendClicked.emit(friend);
    }
}
