import { Component, Input, TemplateRef, ViewEncapsulation } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-logo-popup',
    templateUrl: './logo-popup.component.html',
    styleUrls: ['./logo-popup.component.scss']
})
export class LogoPopupComponent {
    @Input() logoImageSrc: string;
    @Input() imgHeight: string;
    @Input() imgWidth: string;
    @Input() modalHeader: string;
    @Input() modalBody: string;
    @Input() customTemplate: TemplateRef<any>;

    constructor(private dialog: MatDialog) {
    }

    openDialog() {
        const dialogRef = this.dialog.open(LogoPopupContentComponent, {
            maxWidth: '600px',
            panelClass: 'logo-popup-custom-panel-class'
        });
        dialogRef.componentInstance.modalHeader = this.modalHeader;
        dialogRef.componentInstance.modalBody = this.modalBody;
        dialogRef.componentInstance.customTemplate = this.customTemplate;
    }
}
@Component({
    selector: 'app-logo-popup-content',
    templateUrl: './logo-popup-content.component.html',
    styleUrls: ['./logo-popup-content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LogoPopupContentComponent {
    modalHeader: string;
    modalBody: string;
    customTemplate: TemplateRef<any>;
}