<div #htmlElement>
    <div class="panel-header">
        <a href="#" (click)="$event.preventDefault()" (keydown.enter)="showCollapse()">
            {{navigationLinks.label}} <i class="fa fa-caret-down"></i>
        </a>
    </div>
    <div class="collapse selections-list" [id]="id">
        <ul>
            <li *ngFor="let link of navigationLinks.subLinks">
                <div class="selection-body">
                    <a [routerLink]="link.path" (keydown.enter)="hideCollapse()">{{link.label}}</a>
                </div>
            </li>
        </ul>
    </div>
</div>