import { NgModule } from "@angular/core";
import { TowerOfHanoiModule } from "../components/tower-of-hanoi/tower-of-hanoi.module";
import { JustForFunComponent } from "./just-for-fun.component";

@NgModule({
    imports: [
        TowerOfHanoiModule
    ],
    declarations: [
        JustForFunComponent
    ],
    exports: [
        JustForFunComponent
    ]
})
export class JustForFunModule {}