<div class="title-container pb-3">
    <h1>Zero Day</h1>
    <div>Art by Christopher Medeiros</div>
</div>
<div *ngFor="let update of gameUpdates"
     class="card updates-container mt-3">
    <app-game-update [width]="update.width"
                     [header]="update.header"
                     [bodyText]="update.bodyText"
                     [imagePath]="update.imagePath"></app-game-update>
</div>