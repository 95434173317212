export interface PanelLink {
    label: string;
    path: string;
}

export interface NavigationLinks {
    label: string;
    subLinks: PanelLink[];
}

export const HeaderNavigationLinks: NavigationLinks[] = [
    {label: 'Components', subLinks: [
        {label: 'Graphs', path: 'graphs'},
        {label: 'Table with Filters', path: 'table'},
        {label: 'Tower of Hanoi', path: 'just-for-fun'},
        {label: 'Crypto Ticker', path: 'crypto-ticker'},
        {label: 'Forms With Validations', path: './dynamicform'},
        //{label: 'Chat App - WIP', path: 'chat'}
    ]},
    {label: 'Game Development', subLinks: [
        {label: 'Zero Day', path: 'zero-day'}
    ]}
]
