<div class="main-container">
    <div class="conversation-area" *ngIf="currentFriendSelected">
        <div class="chat-area-header">
            <div class="header-container" #header>
                Now Chatting with {{currentFriendSelected}}
            </div>
            <div class="x-container">
                <button (click)="chatClose()" aria-label="close chat window"><i class="fas fa-times"></i></button>
            </div>
        </div>
        <ol class="messages-container" #messagesContainer>
            <li *ngFor="let message of currentChat.messages" class="message-container">
                <div [innerText]="message.text" [ngClass]="{'message-to-friend': message.sentByMe, 'message-from-friend': !message.sentByMe}">
                </div>
            </li>
        </ol>
    </div>
    <div class="input-container" *ngIf="currentFriendSelected">
        <form class="input-text-area" [formGroup]="formGroup">
            <textarea #messageInput
                      [formControlName]="'chatInput'" 
                      placeholder="Enter Message..."
                      class="chat-input"
                      (keydown)="inputAreaAdjust()"
                      (focus)="scrollToTarget($event)"
                      (keydown.enter)="sendChatMessage()"
                      type="text"></textarea>
        </form>
        <button *ngIf="showSend" type="submit" (click)="sendChatMessage()"><i class="fas fa-arrow-circle-up"></i></button>
    </div>
</div>
