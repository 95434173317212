import { NgModule } from "@angular/core";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DragAndDropComponent } from "./drag-and-drop.component";
import { CommonModule } from "@angular/common";
import { TowerOfHanoiModule } from "../tower-of-hanoi/tower-of-hanoi.module";

@NgModule({
    imports: [
        CommonModule,
        DragDropModule,
        TowerOfHanoiModule
    ],
    declarations: [
        DragAndDropComponent
    ],
    exports: [
        DragAndDropComponent
    ]
})
export class DragAndDropModule {}