import { NgModule } from "@angular/core";
import { GraphsAndChartsComponent } from "./graphs-and-charts.component";
import { HighchartsChartModule } from 'highcharts-angular';
import { AppCommonModule } from "../app-common/app-common.module";
import { CommonModule } from "@angular/common";
import { ChartsModule } from "ng2-charts";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    imports: [
        CommonModule,
        HighchartsChartModule,
        ChartsModule,
        AppCommonModule,
        ReactiveFormsModule
    ],
    declarations: [
        GraphsAndChartsComponent
    ],
    exports: [
        GraphsAndChartsComponent
    ]
})
export class GraphsAndChartsModule {
    
}