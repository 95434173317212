<div class="row mb-3">
    <div class="card col-12 hanoi-container">
        <div class="card-header">
            <h1>Tower of Hanoi!</h1>
        </div>
        <app-tower-of-hanoi></app-tower-of-hanoi>
    </div>
</div>
<div class="card shopping-list">
    <div class="card-header">
        <h1>Drag Complex Objects</h1>
    </div>
    <div class="row m-3">
        <div class="col-xs-12 col-sm-6">
            <h2>Available</h2>
            <div class="list-container">
                <div cdkDropList class="list" id="options-list" [cdkDropListConnectedTo]="['cart-list']"
                    [cdkDropListData]="shoppingOptions" (cdkDropListDropped)="drop($event)">
                    <div cdkDrag class="list-item" [cdkDragData]="item" *ngFor="let item of shoppingOptions">
                        <div class="item-info">
                            <p>{{item.name}}</p>
                            <p class="mb-0">{{item.price | currency}}</p>
                        </div>
                        <div class="item-img">
                            <img src="{{'assets/shopping-list-pictures/' + item.imageSource}}">
                        </div>
                        <div class="item-qty">
                            Qty: {{item.quantity}}
                            <i class="fas fa-angle-up" (click)="changeQuantity(item, true)"></i>
                            <i class="fas fa-angle-down" (click)="changeQuantity(item, false)"></i>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6">
            <h2>Cart</h2>
            <div class="list-container">
                <div cdkDropList class="list" id="cart-list" [cdkDropListConnectedTo]="['options-list']"
                    [cdkDropListData]="shoppingCart" (cdkDropListDropped)="drop($event)">
                    <div cdkDrag class="list-item" [cdkDragData]="item" *ngFor="let item of shoppingCart">
                        <div class="item-info">
                            <p>{{item.name}}</p>
                            <p class="mb-0">{{item.price | currency}}</p>
                        </div>
                        <div class="item-img">
                            <img src="{{'assets/shopping-list-pictures/' + item.imageSource}}">
                        </div>
                        <div class="item-qty">
                            Qty: {{item.quantity}}
                            <i class="fas fa-angle-up" (click)="changeQuantity(item, true)"></i>
                            <i class="fas fa-angle-down" (click)="changeQuantity(item, false)"></i>
                        </div>
                    </div>
                </div>
            </div>
            <p>Total: {{costTotal | currency}}</p>
        </div>
    </div>
</div>