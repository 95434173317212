<!-- <div class="row ml-1 mr-3 kanye-row">
    <div class="col-4 col-md-3 kanye-image">
    </div>
    <div class="col-8 col-md-7 kanye-quote">
        {{kanyeQuote}}
        <p class="kanye-rest-credit pt-3">(Powered by the Kanye REST API)</p>
    </div>
</div> -->
<div class="card col-12 hanoi-container">
    <div class="card-header">
        <h1>Tower of Hanoi!</h1>
        <div>Move the rings from the left pole to the right pole without ever placing a ring on top of a smaller ring.</div>
    </div>
    <app-tower-of-hanoi></app-tower-of-hanoi>
</div>