<div class="row" [ngClass]="{'flex-reverse': rightImage}">
    <div class="col-12 col-md-5 image-container">
        <img class="update-image" [src]="imagePath" [style.width.px]="width">
    </div>
    <div class="col-12 col-md-7">
        <div class="pb-3 px-3">
            <h2 class="text-center pt-3">{{header}}</h2>
            {{bodyText}}
        </div>
    </div>
</div>