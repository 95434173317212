import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { CryptoTickerComponent } from './crypto-ticker.component';
import { CryptoTickerService } from './crypto-ticker.service';

@NgModule({
    imports: [
        ChartsModule,
        CommonModule
    ],
    providers: [
        CryptoTickerService
    ],
    declarations: [
        CryptoTickerComponent
    ],
    exports: [
        CryptoTickerComponent
    ]
})
export class CryptoTickerModule {}