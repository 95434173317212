import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TowerOfHanoiComponent } from "./tower-of-hanoi.component";

@NgModule({
    imports: [
        CommonModule,
        DragDropModule
    ],
    declarations: [
        TowerOfHanoiComponent
    ],
    exports: [
        TowerOfHanoiComponent
    ]
})
export class TowerOfHanoiModule {
    
}