import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http"
import { Observable } from 'rxjs';

@Injectable()
export class IEXCloudService {
    private urlPrefix = 'https://sandbox.iexapis.com/stable/stock/';
    private urlPostfix = '/chart/1m?token=Tsk_43d3471f1726492aa66965aa7f9a7756';

    constructor(private http: HttpClient) {
    }

    public getStocksData(symbol: string): Observable<any> {
         return this.http.get(this.urlPrefix + symbol + this.urlPostfix);
    }
}