<div class="header-container {{lookAndFeelService.getLookAndFeel()}}">
    <!-- <div class="logo-container px-3">
        <i class="fas fa-laptop-code" aria-hidden="true"></i>
    </div> -->
    <!-- Desktop View -->
    <div class="desktop-container">
        <div class="row upper-container mx-0">
            <div class="site-title">
                Erik Green
            </div>
            <div class="ml-3">
                <app-look-and-feel></app-look-and-feel>
            </div>
        </div>
        <div class="row lower-container mx-0">
            <div class="option-container">
                <a routerLink="/">Home</a>
            </div>
            <ng-container class="option-container" *ngFor="let link of headerLinks; let i = index">
                <div class="option-container">
                    <app-dropdown-panel [navigationLinks]="link" [id]="'navCollapse' + i"></app-dropdown-panel>
                </div>
            </ng-container>
        </div>
    </div>
    <!-- Mobile View -->
    <div class="mobile-container">
        <app-navbar-accordion [navigationLinks]="headerLinks"></app-navbar-accordion>
    </div>
</div>
