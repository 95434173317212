import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { Component } from "@angular/core";

interface ShoppingItem {
    name: string;
    price: number;
    imageSource: string;
    quantity: number;
}
@Component({
    selector: 'app-drag-and-drop',
    templateUrl: './drag-and-drop.component.html',
    styleUrls: ['./drag-and-drop.component.scss']
})
export class DragAndDropComponent {
    shoppingOptions: ShoppingItem[] = [
        {
            name: 'Banana',
            price: 1.50,
            imageSource: 'banana.jpg',
            quantity: 1
        },
        {
            name: 'Tomato',
            price: 3.75,
            imageSource: 'tomato.jpg',
            quantity: 1
        }
    ];
    shoppingCart: ShoppingItem[] = [];
    costTotal: number = 0;

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
                this.updateCostTotal();
        }
    }

    changeQuantity(item: ShoppingItem, increase: boolean) {
        if (increase) {
            if (item.quantity != 99) {
                item.quantity++;
            }
        } else {
            if (item.quantity != 1) {
                item.quantity--;
            }
        }
        this.updateCostTotal();
    }

    updateCostTotal() {
        this.costTotal = 0;
        this.shoppingCart.forEach((item) => {
            this.costTotal += item.price * item.quantity;
        });
    }
}