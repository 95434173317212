import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { MainPageModule } from './main-page/main-page.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AlphaAdvantageService } from './services/alpha-advantage/alpha-advantage.service';
import { IEXCloudService } from './services/iex-cloud/iex-cloud.service';
import { ChatAppModule } from './components/chat-app/chat-app.module';
import { DynamicFormModule } from './components/dynamic-form/dynamic-form.module';
import { StateServcie } from './app-common/services/state-service.service';
import { LookAndFeelService } from './app-common/services/look-and-feel.service';
import { GraphsAndChartsModule } from './graphs-and-charts/graphs-and-charts.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragAndDropModule } from './components/drag-and-drop/drag-and-drop.module';
import { EmbeddedComponentsModule } from './embedded-components/embedded-components.module';
import { TableWithFiltersModule } from './components/table-with-filters/table-with-filters.module';
import { JustForFunModule } from './just-for-fun/just-for-fun.module';
import { CryptoTickerModule } from './components/crypto-ticker/crypto-ticker.module';
import { ZeroDayUpdatesModule } from './zero-day-updates/zero-day-updates.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    GraphsAndChartsModule,
    EmbeddedComponentsModule,
    AppRoutingModule,
    LayoutModule,
    MainPageModule,
    ChatAppModule,
    DragAndDropModule,
    DynamicFormModule,
    BrowserAnimationsModule,
    TableWithFiltersModule,
    JustForFunModule,
    CryptoTickerModule,
    ZeroDayUpdatesModule
  ],
  providers: [ AlphaAdvantageService, IEXCloudService, StateServcie, LookAndFeelService ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
