import { Component, Input } from "@angular/core";
import { NavigationLinks } from 'src/app/layout/header/header-navigation-links';

@Component({
    selector: 'app-navbar-accordion',
    templateUrl: './navbar-accordion.component.html',
    styleUrls: ['./navbar-accordion.component.scss']
})
export class NavbarAccordionComponent {
    @Input() navigationLinks: NavigationLinks[];
}