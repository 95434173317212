import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-just-for-fun',
    templateUrl: './just-for-fun.component.html',
    styleUrls: ['./just-for-fun.component.scss']
})
export class JustForFunComponent implements OnInit {
    kanyeRest = 'https://api.kanye.rest/';
    kanyeQuote: string;
    badWords = [
        'fuck',
        ' ass',
        'shit',
        'bitch',
        'nigga',
        'dick'
    ];
    constructor(private http: HttpClient) {
        
    }
    ngOnInit() {
        this.http.get(this.kanyeRest).subscribe((resp) => {
            this.kanyeQuote = this.removeBadWords(resp['quote']);
        });
    }
    removeBadWords(quote: string): string {
        this.badWords.forEach((word) => {
            while(quote.toLowerCase().indexOf(word) >= 0) {
                let startIndex = quote.toLowerCase().indexOf(word);
                if (word[0] === ' ') {
                    startIndex += 1;
                    word = word.slice(1);
                }
                quote = quote.slice(0, startIndex) + "!#@&" + quote.slice(startIndex + word.length);
            }
        });
        return quote;
    }
}